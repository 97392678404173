@import url("./animations.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

.header-text-space {
  letter-spacing: 0.6rem;
}

.text-custom {
  font-size: 22px;
}

@media screen and (min-width: 768px) {
  .text-custom {
    font-size: 32px;
  }
}
