.marquee-w {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.marquee {
  position: absolute;
  display: block;
  margin: auto auto;
  white-space: nowrap;
  overflow: hidden;
  min-width: 100%;
}

.marquee p {
  display: inline-block;
  padding-left: 60%;
  text-align: center;
  white-space: nowrap;
  min-width: 100%;
  animation: marquee 50s linear infinite;
}

.marquee2 p {
  animation-delay: 25s;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

@media screen and (min-width: 500px) {
  .marquee p {
    padding-left: 60%;
  }

  .marquee2 p {
    padding-left: 63%;
  }
}

@media screen and (min-width: 768px) {
  .marquee p {
    padding-left: 70%;
  }

  .marquee2 p {
    padding-left: 69%;
  }
}
